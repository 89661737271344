<!-------------------------------------------------
description:  财务管理 发票管理 已开票
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <!-------------------------------------------------
      description:申请信息
      --------------------------------------------------->
      <application-information :info="formData" />
      <!-------------------------------------------------
      description:地址信息
      --------------------------------------------------->
      <address-info :info="formData" />
      <!-------------------------------------------------
      description:开票信息
      --------------------------------------------------->
      <billing-information :info="formData" />
    </div>
  </scroll-layout>
</template>

<script>
  import { invoiceManagements } from '@/service/apis';

  import AddressInfo from './components/AddressInfo.vue';
  import BillingInformation from './components/BillingInformation.vue';
  import ApplicationInformation from './components/ApplicationInformation.vue';

  export default {
    name: 'InvoiceDetailsIssued',
    components: {
      AddressInfo,
      BillingInformation,
      ApplicationInformation,
    },
    data() {
      return {
        formData: {},
        id: '',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      invoiceManagements.detail(this.$route.query).then(res => {
        this.formData = res || {};
      });
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .el-form {
    width: 450px;
    text-align: left;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .box-card {
      width: 100%;
      margin: 0 5px;
      &:first-child {
        //margin-left: 0;
      }
      &:last-child {
        //margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
